import { Getter } from "vuex";
import { IState } from './state';

const name: Getter<IState, IState> = (state) => state.name;
const description: Getter<IState, IState> = (state) => state.description;
const label: Getter<IState, IState> = (state) => state.label;
const url: Getter<IState, IState> = (state) => state.url;
const backgroundColor: Getter<IState, IState> = (state) => state.backgroundColor;

export default {
	name,
	description,
	url,
	label,
	backgroundColor
}

export interface IState {
	name: string,
	description: string,
	label: string,
	url: string,
	backgroundColor: string
}
const deafultState: IState = {
	name: '',
	description: '',
	label: '',
	url: '',
	backgroundColor: ''
}

export default deafultState;

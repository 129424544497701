





















import Vue from 'vue'

interface CardComputed {
	name: string,
	description: string,
	label: string,
	url: string,
	bgColor: string,
}

export default Vue.extend<{}, {}, CardComputed, {}>({
	name: 'card',
	computed: {
		name() { return this.$store.getters.name },
		description() { return this.$store.getters.description },
		label() { return this.$store.getters.label },
		url() { return this.$store.getters.url },
		bgColor() { return this.$store.getters.backgroundColor }
	}
})
